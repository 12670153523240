<template>
    <ScrollTop>
        <template #icon>
            <span class="flex icon-chevron-up text-primary" />
        </template>
    </ScrollTop>
</template>

<script setup lang="ts">
import ScrollTop from 'primevue/scrolltop';

/** Data **/
const pt = readonly({
    root: 'scroll-top',
    button: 'scroll-top__button'
})
</script>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.p-scrolltop {
    padding: 10px;
    background-color: $color-primary-lighter-2;
    box-shadow: 0 1px 5px 1px rgba(4, 27, 45, 0.15), 0 1px 5px 0 rgba(4, 27, 45, 0.15);
    transition: $transition;

    &:not(:disabled) {
        &:hover {
            background-color: $color-primary-lighter;
        }
    }

    &:hover {
        background-color: $color-primary-lighter;
    }
}
</style>
